import * as React from "react"
import Layout from "../components/layout"
import Head from "../components/head"
import { graphql, Link, useStaticQuery } from "gatsby"

const ProjectsPage = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          author
          title
        }
      }
      allContentfulProject(sort: { fields: startingYear, order: DESC }) {
        edges {
          node {
            icon {
              file {
                url
              }
            }
            description
            id
            pretitle
            title
            endingYear
            startingYear
            linkText
            linkUrl
          }
        }
      }
    }
  `)

  const projects = data.allContentfulProject.edges

  return (
    <Layout>
      <Head title={`Projects | ${data.site.siteMetadata.author}`} />
      <div className="my-4 sm:my-12 px-4">
        <h1 className="text-zinc-100 text-5xl font-bold mb-4">Projects</h1>
        <p className="text-zinc-400 text-lg mb-4">
          A lot of my experience and knowledge was acquired outside jobs and
          studies. These are some of the things I learned a lot from.
        </p>
        <ol className="grid grid-cols-1 gap-3">
          {projects.map(edge => {
            return (
              <li
                className={`sm:flex sm:flex-row items-start px-5 py-6 border-zinc-800 border-2 sm:bg-transparent rounded-md ring-1 ring-zinc-900/10 shadow-sm}`}
                key={`${edge.node.id}`}
              >
                <img
                  className="w-12 mb-auto mt-1 rounded-full"
                  src={edge.node.icon.file.url}
                  alt={edge.node.icon.title}
                />

                <div className="flex flex-row align-top mt-2 sm:mt-0 sm:ml-3">
                  <div className="flex-col">
                    <h2 className="text-zinc-200 text-2xl mt-2 sm:mt-0 group font-semibold">
                      {edge.node.title}
                    </h2>
                    <p className="font-normal text-zinc-500 mt-2 sm:mt-0">
                      {`${edge.node.pretitle} (${edge.node.startingYear} - ${
                        edge.node.endingYear && edge.node.endingYear
                      })`}
                    </p>
                    <p className="text-zinc-400 leading-7 mt-2">
                      {edge.node.description}
                    </p>
                    <p className="mt-2">
                      <Link
                        className="underline text-indigo-500"
                        to={edge.node.linkUrl}
                      >
                        {edge.node.linkText}
                      </Link>
                    </p>
                  </div>
                </div>
              </li>
            )
          })}
        </ol>
      </div>
    </Layout>
  )
}

export default ProjectsPage
